import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Delete,
    Drafts,
    FiberManualRecord,
    Markunread
} from '@mui/icons-material';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllNotifications, selectAllNotifications } from '../../redux/actions/notifications';

import { NotificationType } from '../../types';
import {
    deleteNotifications,
    setReadNotifications,
    setUnreadNotifications
} from '../../api/notifications';
import toast from '../../utils/toast';
import { COLOR_RED } from '../../theme';

function getReadIcon(notification: NotificationType) {
    return !notification.isRead
        ? <FiberManualRecord sx={{ fontSize: 10, color: COLOR_RED }} />
        : '';
}

export default function Notifications() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notifications = useSelector(selectAllNotifications());

    React.useEffect(() => {
        dispatch(fetchAllNotifications());
    }, [dispatch]);

    const columns = React.useMemo(() => ([{
        name: 'Datum',
        key: 'createdAt',
        type: 'datetime' as 'datetime'
    }, {
        name: 'Meddelande',
        key: 'message',
    }, {
        name: 'Oläst',
        key: 'custom-read',
        align: 'right',
        getValue: getReadIcon
    }]), []);

    const getRowStyle = React.useCallback((notification: NotificationType) => (
        notification.isRead ? {} : { '& > *': { fontWeight: 'bold !important' } }
    ), []);

    const navigateTo = React.useCallback(({
        orderId,
        offerId,
        jobId,
        groupId,
        capacityId
    }: NotificationType) => {
        if (orderId && offerId) {
            navigate(`/orders/${orderId}?ooid=${offerId}`);
        } else if (orderId) {
            navigate(`/orders/${orderId}`);
        } else if (jobId) {
            navigate(`/jobs/${jobId}`);
        } else if (groupId) {
            navigate(`/groups/${groupId}`);
        } else if (capacityId) {
            navigate(`/capacities/${capacityId}?o=true`);
        } else {
            toast('Objektet är inte längre tillgängligt');
        }
    }, [navigate]);

    const markAsRead = React.useCallback(async (selectedNotifications: NotificationType[]) => {
        try {
            await setReadNotifications(selectedNotifications.map((n) => n.id));
            dispatch(fetchAllNotifications());
            if (selectedNotifications.length === 1) {
                toast('1 notis markerad som läst');
            } else {
                toast(`${selectedNotifications.length} händelser markerade som lästa`);
            }
        } catch (e) {
            toast(e, 'Kunde inte markera händelser som lästa, försök igen');
        }
    }, [dispatch]);

    const markAsUnread = React.useCallback(async (selectedNotifications: NotificationType[]) => {
        try {
            await setUnreadNotifications(selectedNotifications.map((n) => n.id));
            dispatch(fetchAllNotifications());
            if (selectedNotifications.length === 1) {
                toast('1 notis markerad som oläst');
            } else {
                toast(`${selectedNotifications.length} händelser markerade som olästa`);
            }
        } catch (e) {
            toast(e, 'Kunde inte markera händelser som olästa, försök igen');
        }
    }, [dispatch]);

    const removeNotifications = React.useCallback(async (selectedNotifications: NotificationType[]) => {
        try {
            await deleteNotifications(selectedNotifications.map((n) => n.id));
            dispatch(fetchAllNotifications());
            if (selectedNotifications.length === 1) {
                toast('1 notis borttagen');
            } else {
                toast(`${selectedNotifications.length} händelser borttagna`);
            }
        } catch (e) {
            toast(e, 'Kunde inte ta bort händelser, försök igen');
        }
    }, [dispatch]);

    return (
        <Container>
            <Header title="Händelser" />

            <Paper padding={0}>
                <Table
                    name="notifications"
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                    columns={columns}
                    data={notifications}
                    batchActions={[
                        {
                            name: 'Markera som oläst',
                            icon: <Markunread />,
                            action: markAsUnread
                        },
                        {
                            name: 'Markera som läst',
                            icon: <Drafts />,
                            action: markAsRead
                        },
                        {
                            name: 'Ta bort',
                            icon: <Delete />,
                            color: 'error',
                            action: removeNotifications
                        },
                    ]}
                    onRowClick={navigateTo}
                    getRowStyle={getRowStyle}
                    emptyText="Du har inga händelser."
                />
            </Paper>
        </Container>
    );
}
