import { getCoordinateDistance } from './geometry';

type FilterPropsType = {
    entities: any,
    filters: any
}

// eslint-disable-next-line import/prefer-default-export
export function filterMarketEntities({ entities, filters: f }: FilterPropsType) {
    return typeof f !== 'object' ? entities
        : entities.filter((e: any) => (
            f.isJob || (e.type === 'CAP')
        ) && (
            f.isCapacity || (e.type !== 'CAP')
        ) && (
            !(f.pickupLocationLat && f.pickupLocationLng && f.pickupLocationRadius)
            || getCoordinateDistance(
                { lat: f.pickupLocationLat, lng: f.pickupLocationLng },
                { lat: e.pickupLat, lng: e.pickupLng }
            ) < f.pickupLocationRadius
        ) && (
            !(f.deliveryLocationLat && f.deliveryLocationLng && f.deliveryLocationRadius)
            || getCoordinateDistance(
                { lat: f.deliveryLocationLat, lng: f.deliveryLocationLng },
                { lat: e.deliveryLat, lng: e.deliveryLng }
            ) < f.deliveryLocationRadius
        ) && (
            !f.firstPickupDate || (e.firstPickupDate >= f.firstPickupDate)
        ) && (
            !f.lastDeliveryDate || (e.lastDeliveryDate <= f.lastDeliveryDate)
        ) && (
            !f.minGrossWeight || (e.grossWeight >= f.minGrossWeight)
        ) && (
            !f.maxGrossWeight || (e.grossWeight <= f.maxGrossWeight)
        ) && (
            !f.minChargeableWeight || (e.chargeableWeight >= f.minChargeableWeight)
        ) && (
            !f.maxChargeableWeight || (e.chargeableWeight <= f.maxChargeableWeight)
        ) && (
            !f.minVolume || (e.volume >= f.minVolume)
        ) && (
            !f.maxVolume || (e.volume <= f.maxVolume)
        ) && (
            !f.minLoadingMeters || (e.loadingMeters >= f.minLoadingMeters)
        ) && (
            !f.maxLoadingMeters || (e.loadingMeters <= f.maxLoadingMeters)
        ) && (
            !f.minPalletPlaces || (e.palletPlaces >= f.minPalletPlaces)
        ) && (
            !f.maxPalletPlaces || (e.palletPlaces <= f.maxPalletPlaces)
        ) && (
            !f.minPallets || (e.pallets >= f.minPallets)
        ) && (
            !f.maxPallets || (e.pallets <= f.maxPallets)
        // ) && (
        //     !f.minPieces || (e.pieces >= f.minPieces)
        // ) && (
        //     !f.maxPieces || (e.pieces <= f.maxPieces)
        ) && (
            !f.groupId || (e.groupIds.some((gid: number) => gid === f.groupId))
        ) && (
            !f.hasListPrice || e.type === 'CAP' || e.listPrice !== null
        ) && (
            !f.hasOffer || e.type === 'CAP' || !!e.offer
        ) && (
            !f.takesOffer || e.type === 'CAP' || (
                e.type === 'LL' && (e.expiresAt === null || e.expiresAt > (new Date()).toISOString())
            )
        ) && (
            !f.locationQuery || (
                e.pickupCity.toLowerCase().includes(f.locationQuery.toLowerCase())
                || e.deliveryCity.toLowerCase().includes(f.locationQuery.toLowerCase())
            )
        ));
}
