import React from 'react';
import { Button, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { Popup } from '../CommonComponents';
import toastMessage from '../../utils/toast';

import {
    // JobType,
    OfferType,
    // OrderType
} from '../../types';
import { formatNumber } from '../../utils/parsing';
import { withdrawOffer } from '../../api/offers';

type PropsType = {
    // order: OrderType | JobType;
    offer: OfferType;
    refetch: () => void;
};

export default function WithdrawOffer({ offer, refetch }: PropsType) {
    const [popupIsOpen, setPopupIsOpen] = React.useState(false);

    const reject = React.useCallback(async () => {
        try {
            await withdrawOffer({ id: offer.id });
            setPopupIsOpen(false);
            toastMessage('Prisförslag tillbakadraget.');
        } catch (e) {
            toastMessage(e, 'Kunde inte dra tillbaka prisförslag, försök igen');
        } finally {
            refetch();
        }
    }, [offer.id, refetch]);

    return (
        <>
            <Popup
                open={popupIsOpen}
                title="Dra tillbaka prisförslag"
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={reject}
                handleClose={() => setPopupIsOpen(false)}
            >
                <Typography>
                    {`Vill ni verkligen dra tillbaka ert prisförslag ${formatNumber(offer.price, 'SEK')}?`}
                </Typography>

                <Typography>
                    Ni kan alltid skicka ett nytt prisförslag om ni skulle ändra er.
                </Typography>
            </Popup>

            <Button
                variant="outlined"
                color="error"
                startIcon={<Close />}
                onClick={() => setPopupIsOpen(true)}
                fullWidth
            // disabled={!order.isOpen}
            >
                Dra tillbaka
            </Button>
        </>
    );
}
