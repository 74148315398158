import { Notifications } from '@mui/icons-material';
import {
    Badge,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
    selectNotificationsCount,
    fetchNotificationsCount
} from '../../redux/actions/notifications';

type PropsType = {
    backgroundColor: string,
    textColor: string,
    onClick: () => void
};

const FETCH_INTERVAL = 1 * 60 * 1000;

export default function NotificationsMenuItem({
    backgroundColor,
    textColor,
    onClick
}: PropsType) {
    const dispatch = useDispatch();
    const notificationsCount = useSelector(selectNotificationsCount());

    React.useEffect(() => {
        dispatch(fetchNotificationsCount());
        const intervalId = window.setInterval(() => {
            dispatch(fetchNotificationsCount());
        }, FETCH_INTERVAL);
        return () => window.clearInterval(intervalId);
    }, [dispatch]);

    return (
        <ListItemButton
            key="notifications"
            onClick={onClick}
            sx={{ background: backgroundColor }}
        >
            <ListItemIcon sx={{ color: textColor }}>
                <Badge
                    badgeContent={notificationsCount}
                    color="error"
                >
                    <Notifications />
                </Badge>
            </ListItemIcon>
            <ListItemText primary="Händelser" sx={{ color: textColor }} />
        </ListItemButton>
    );
}
