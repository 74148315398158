import React, { useRef } from 'react';
import { Grid } from '@mui/material';

import { Block, Table } from '../CommonComponents';
import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllOffersByOrderId, selectAllOffersByOrderId } from '../../redux/actions/offers';
import { OrderType } from '../../types';
import { offerStatus, orderStatus } from '../../utils/status';
import { OfferPriceChip, OfferStatusChip } from '../Offers/OfferChips';
import EditOffer from '../Offers/EditOffer';
import RejectOffer from '../Offers/RejectOffer';
import AcceptOffer from '../Offers/AcceptOffer';
import OfferChatButton from '../Offers/OfferChatButton';
import { COLOR_SKIN } from '../../theme';
import WithdrawOffer from '../Offers/WithdrawOffer';

type PropsType = {
    order: OrderType;
    openOfferId?: number;
    refetch: () => void;
}

const FETCH_INTERVAL = 60 * 1000; // 60 seconds

export default function OrderOffers({ order, openOfferId, refetch }: PropsType) {
    const dispatch = useDispatch();
    const offers = useSelector(selectAllOffersByOrderId(order.id));
    const containerRef = useRef<HTMLDivElement>(null);

    const fetchOffers = React.useCallback(() => {
        if (order.id) {
            dispatch(fetchAllOffersByOrderId(order.id));
        }
    }, [dispatch, order.id]);

    const completeRefetch = React.useCallback(() => {
        fetchOffers();
        refetch();
    }, [fetchOffers, refetch]);

    React.useEffect(() => {
        let timeoutId: number | undefined;
        if (openOfferId && containerRef.current) {
            timeoutId = window.setTimeout(() => {
                containerRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 500);
        }
        return () => window.clearTimeout(timeoutId);
    }, [openOfferId]);

    React.useEffect(() => {
        fetchOffers();
        const intervalId = window.setInterval(fetchOffers, FETCH_INTERVAL);

        return () => window.clearInterval(intervalId); // Runs before every effect-run, and on unmount
    }, [fetchOffers]);

    const getRowStyle = React.useCallback((offer) => (
        offer.id === openOfferId
            ? { backgroundColor: COLOR_SKIN }
            : {}
    ), [openOfferId]);

    const getActionButtons = React.useCallback((offer) => (
        <Grid container spacing={1} justifyContent="flex-end">
            {
                offer.status === offerStatus.PENDING
                && order.status === orderStatus.PUBLISHED
                && !order.isArchived
                && offer.price
                && (offer.isOwnOffer ? (
                    <Grid item>
                        <WithdrawOffer
                            offer={offer}
                            refetch={completeRefetch}
                        />
                    </Grid>
                ) : (
                    <>
                        <Grid item>
                            <AcceptOffer
                                offer={offer}
                                refetch={completeRefetch}
                                userIsOrderOwner
                                offeringTenantName={offer.tenantName}
                            />
                        </Grid>
                        <Grid item>
                            <RejectOffer
                                offer={offer}
                                refetch={completeRefetch}
                                offeringTenantName={offer.tenantName}
                            />
                        </Grid>
                    </>
                ))
            }
            {order.status === orderStatus.PUBLISHED && !order.isArchived && (
                <Grid item>
                    <EditOffer order={order} offer={offer} refetch={fetchOffers} />
                </Grid>
            )}
            <Grid item>
                <OfferChatButton
                    openInitially={offer.id === openOfferId}
                    offer={offer}
                    orderId={order.id}
                    canWrite={order.acceptedOfferId === offer.id || (
                        order.status === orderStatus.PUBLISHED && !order.isArchived
                    )}
                    recipient={offer.tenantName}
                    refetch={fetchOffers}
                />
            </Grid>
        </Grid>
    ), [completeRefetch, fetchOffers, openOfferId, order]);

    return (
        <div ref={containerRef}>
            <Block
                title="Intresseanmälan"
            // ActionComponent={(
            //     <div>
            //         Historik
            //     </div>
            // )}
            >
                <Table
                    name="dashboardActiveOffers"
                    columns={[{
                        name: 'Skickat',
                        key: 'priceAt',
                        type: 'datetime'
                    }, {
                        name: 'Företag',
                        key: 'tenantName'
                    }, {
                        name: 'Prisförslag',
                        key: 'price',
                        getValue: OfferPriceChip
                    }, {
                        name: 'Status',
                        key: 'customStatus',
                        getValue: OfferStatusChip
                    }, {
                        name: '',
                        key: 'customActions',
                        align: 'right',
                        getValue: getActionButtons
                    }]}
                    data={offers}
                    getRowStyle={getRowStyle}
                    emptyText="Det finns inga intresseanmälningar."
                />
            </Block>
        </div>
    );
}
