export const COLOR_TEAL = '#016D77';
export const COLOR_SKIN = '#FFDDD2';
export const COLOR_MINT = '#83c5be';
export const COLOR_PURPLE = '#522653';
export const COLOR_PURPLE_LIGHT = '#795679';
export const COLOR_YELLOW = '#FDB827';
export const COLOR_GREY = '#868193';
export const COLOR_DARK_GREY = '#555';
export const COLOR_WHITEISH = '#EDF6F9';
export const COLOR_RED = '#FF0000';
export const COLOR_NEW_BLUE = '#0E2A43';
