import React from 'react';
import {
    Box,
    Divider,
    Grid,
    Typography
} from '@mui/material';

import { Paper } from '../../StyledComponents';
import { Numbers, SectionDivider } from '../../CommonComponents';
import EntityDriveLine from './EntityDriveline';

import { formatDateTime } from '../../../utils/parsing';
import { useDispatch, useSelector } from '../../../redux/hooks';
import { setSelectedMarketEntity, selectSelectedMarketEntity } from '../../../redux/actions/market';
import DhlDetails from './DhlDetails';
import CapacitySuggestion from './CapacitySuggestion';
import Offer from '../../Offers/Offer';
import OfferChatButton from '../../Offers/OfferChatButton';

export default function EntityDetails() {
    const dispatch = useDispatch();
    const entity = useSelector(selectSelectedMarketEntity());

    const refetch = React.useCallback(() => {
        if (entity?.key) {
            dispatch(setSelectedMarketEntity(entity.key));
        }
    }, [dispatch, entity?.key]);

    // const deselect = React.useCallback(() => {
    //     if (entity?.key) {
    //         dispatch(setSelectedMarketEntity(null));
    //     }
    // }, [dispatch, entity?.key]);

    const publishedText = React.useMemo(() => {
        if (!entity) {
            return '';
        }
        if (entity.type === 'DHL') {
            // return `Uppdrag hämtat ${formatDateTime(entity.publishedAt)} från DHL Overflow.`;
            return `Hämtat ${formatDateTime(entity.publishedAt)} från DHL Overflow.`;
        }
        if (entity.type === 'LL') {
            // return `Uppdrag publicerat ${
            //     formatDateTime(entity.publishedAt)
            // } av ${
            //     entity.tenantName || '[dold uppdragsgivare]'
            // } för ${
            //     entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')
            // }.`;
            return `Publicerat ${
                formatDateTime(entity.publishedAt)
            } av ${
                entity.tenantName || '[dold uppdragsgivare]'
            } för ${
                entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')
            }.`;
        }
        if (entity.type === 'CAP') {
            // return `Kapacitet publicerad ${
            //     formatDateTime(entity.publishedAt)
            // } för ${entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')}.`;
            return `Publicerad ${
                formatDateTime(entity.publishedAt)
            } för ${entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')}.`;
        }
        return '';
    }, [entity]);

    return entity ? (
        <Paper
            sx={{
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                minWidth: '520px',
                maxWidth: '1200px',
                width: 'calc(100% - 40px)',
                p: 1,
                pb: 0
            }}
        >
            <Grid
                container
                justifyContent="center"
                // alignItems="flex-start"
                spacing={2}
            // sx={{ p: 2 }}
            >
                <Grid item xs>
                    <SectionDivider
                        label={entity.type === 'CAP' ? 'Ledig kapacitet' : 'Uppdrag'}
                        marginTop={-2}
                        marginBottom={-1}
                    />
                    <EntityDriveLine entity={entity} />

                    {entity.publicDescription && (
                        <Typography variant="body2" display="block" align="center" sx={{ mt: 1 }}>
                            {entity.publicDescription}
                        </Typography>
                    )}

                    <Numbers
                        small
                        data={[
                            { title: 'Totalvikt [kg]', value: entity.grossWeight },
                            { title: 'FDR-vikt [kg]', value: entity.chargeableWeight },
                            { title: 'Volym [m³]', value: entity.volume },
                            { title: 'Flakmeter', value: entity.loadingMeters },
                            { title: 'Pallplatser', value: entity.palletPlaces },
                            { title: 'Pallar', value: entity.pallets }
                        ]}
                    />

                    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

                    <Typography variant="caption" display="block" align="center">
                        {publishedText}
                    </Typography>
                </Grid>

                <Grid item sx={{ minWidth: 200, maxWidth: 410 }}>
                    <Box
                        sx={{
                            p: 2,
                            pt: 1,
                            mb: 2,
                            borderRadius: 2,
                            backgroundColor: '#F5F5F5'
                        }}
                    >
                        {entity.type === 'LL' && (
                            <>
                                <Offer
                                    order={entity}
                                    offer={entity.offer}
                                    refetch={refetch}
                                />
                                <Divider sx={{ mt: 1 }} />
                                <OfferChatButton
                                    offer={entity.offer}
                                    orderId={entity.id}
                                    canWrite={entity.isOpen}
                                    refetch={refetch}
                                    recipient={entity.tenantName || '[Dold uppdragsgivare]'}
                                    sx={{ width: '100%', mt: 1 }}
                                />
                            </>
                        )}
                        {entity.type === 'CAP' && (
                            <CapacitySuggestion capacity={entity} refetch={refetch} />
                        )}
                        {entity.type === 'DHL' && (
                            <DhlDetails entity={entity} />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    ) : null;
}
