import React from 'react';

import {
    LocationOn,
    Person,
    LocalShipping,
    AssignmentTurnedIn,
    Settings,
    Business,
    People,
    Feedback,
    Mail,
    Groups,
    Notifications,
    Link as LinkIcon,
} from '@mui/icons-material';

type LinkType = {
    name: string;
    icon: JSX.Element;
    path?: string;
    key?: string;
    links?: LinkType[];
    isAdmin?: boolean;
};

export default function getMenuLinks(isAdmin: boolean): LinkType[] {
    const menuLinks = [{
        name: 'Marknad',
        icon: <LocationOn />,
        path: '/market'
    }, {
        name: 'Boka uppdrag',
        icon: <AssignmentTurnedIn />,
        path: '/orders'
    }, {
        name: 'Kör uppdrag',
        icon: <LocalShipping />,
        path: '/jobs'
    }, {
        name: 'Administration',
        icon: <Settings />,
        key: 'admin',
        isAdmin: true,
        links: [{
            name: 'Företagsuppgifter',
            icon: <Business />,
            path: '/company-profile'
            // }, {
            //     name: 'Fakturor',
            //     icon: <Receipt />,
            //     path: '/invoices'
        }, {
            name: 'Användare',
            icon: <People />,
            path: '/users'
            // }, {
            //     name: 'Bilar',
            //     icon: <LocalShipping />,
            //     path: '/cars'
        }, {
            name: 'Grupper',
            icon: <Groups />,
            path: '/groups'
        }, {
            name: 'Integrationer',
            icon: <LinkIcon />,
            path: '/integrations'
        }]
    }, {
        name: 'Mitt konto',
        icon: <Person />,
        key: 'account',
        links: [{
            name: 'Min profil',
            icon: <Person />,
            path: '/user-profile'
        }, {
            name: 'Bevakningar',
            icon: <Notifications />,
            path: '/watchlists'
        }, {
            name: 'Bjud in',
            icon: <Mail />,
            path: '/invite'
        }, {
            name: 'Feedback',
            icon: <Feedback />,
            path: '/feedback'
        }]
    }];

    return isAdmin ? menuLinks : menuLinks.filter((l) => !l.isAdmin);
}
