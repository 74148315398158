import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatDateTime } from '../../utils/parsing';
import { COLOR_DARK_GREY, COLOR_PURPLE, COLOR_SKIN } from '../../theme';
import { OfferMessageType } from '../../types';

type PropsType = {
    message: OfferMessageType;
}

const EVENT_COLOR = '#EEE';
const OWN_COLOR = COLOR_PURPLE;
const OTHER_COLOR = COLOR_SKIN;

export default function OfferChatMessage({ message }: PropsType) {
    return (
        <Box
            sx={{
                maxWidth: '90%',
                ...(message.isCurrentTenant ? { marginLeft: 'auto' } : {}),
                borderRadius: '5px',
                // eslint-disable-next-line no-nested-ternary
                backgroundColor: message.isEvent ? EVENT_COLOR : (
                    message.isCurrentTenant ? OWN_COLOR : OTHER_COLOR
                ),
                // eslint-disable-next-line no-nested-ternary
                color: message.isEvent ? COLOR_DARK_GREY : (
                    message.isCurrentTenant ? 'white' : undefined
                ),
                marginBottom: 1,
                padding: '5px 12px 3px 12px'
            }}
            display="table"
        >
            {message.message.split('\n').map((part, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Typography display="block" key={i} sx={{ fontWeight: message.isEvent ? '500' : undefined }}>
                    {part}
                </Typography>
            ))}
            <Typography variant="caption" display="block">
                {`${message.createdByName}, ${formatDateTime(message.createdAt)}`}
            </Typography>
        </Box>
    );
}
/*

*/
