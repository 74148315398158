import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Grid
} from '@mui/material';
import {
    Add
} from '@mui/icons-material';

import { useDispatch, useSelector } from '../../redux/hooks';

import {
    Block,
    Header,
    Table
} from '../CommonComponents';
import {
    Container,
} from '../StyledComponents';
import ActiveOffers from '../Offers/ActiveOffers';

import { selectUserProfile } from '../../redux/actions/users';
import { fetchOrderStatusCount } from '../../redux/actions/orders';

import { fetchJobStatusCount } from '../../redux/actions/jobs';
import {
    fetchAllOpenCapacities,
    fetchAllOpenJobs,
    resetMarketFilters,
    setMarketFiltersFromWatchlist
} from '../../redux/actions/market';
import { fetchAllWatchlists, selectAllWatchlists } from '../../redux/actions/watchlists';
import { fetchAllGroups, selectMyGroups } from '../../redux/actions/groups';
import { getWatchlist } from '../../api/watchlists';

export default function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userProfile = useSelector(selectUserProfile());
    const watchlists = useSelector(selectAllWatchlists());
    const myGroups = useSelector(selectMyGroups());

    React.useEffect(() => {
        // true = Only fetch if not recently fetched
        // Done here to get the correct values for published orders/jobs
        dispatch(fetchAllOpenJobs(true));
        dispatch(fetchAllOpenCapacities(true));

        dispatch(fetchOrderStatusCount());
        dispatch(fetchJobStatusCount());
        dispatch(fetchAllGroups());
        dispatch(fetchAllWatchlists());
    }, [dispatch]);

    const goToGroupMarket = React.useCallback(({ groupId, groupName }: { groupId: number, groupName: string }) => {
        dispatch(resetMarketFilters({ groupId, groupName }));
        navigate('/market');
    }, [dispatch, navigate]);

    const goToWatchlistMarket = React.useCallback(async ({ watchlistId }: { watchlistId: number }) => {
        const watchlist = await getWatchlist(watchlistId);
        dispatch(setMarketFiltersFromWatchlist(watchlist));
        navigate('/market');
    }, [dispatch, navigate]);

    return (
        <Container>
            <Header
                title={`Hej ${userProfile?.firstName || ''}!`}
                hideBackButton
                CustomComponent={(
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                startIcon={<Add />}
                                onClick={() => navigate('/capacities/create')}
                            >
                                Publicera ledig kapacitet
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => navigate('/orders/create')}
                            >
                                Publicera uppdrag
                            </Button>
                        </Grid>
                    </Grid>
                )}
            />

            <Grid container spacing={2} sx={{ mt: -1 }}>
                <Grid item xs={12}>
                    <ActiveOffers />
                </Grid>

                <Grid item xs={12} xl={6}>
                    <Block
                        title="Bevakningar med aktuella uppdrag"
                        ActionComponent={(
                            <Button
                                variant="outlined"
                                startIcon={<Add />}
                                onClick={() => navigate('/watchlists/create')}
                            >
                                Ny bevakning
                            </Button>
                        )}
                    >
                        <Table
                            name="dashboardWatchlists"
                            columns={[{
                                name: 'Namn',
                                key: 'name',
                                getValue: (watchlist) => watchlist.name || 'Namnlös bevakning'
                            }, {
                                name: 'Senaste uppdrag',
                                key: 'lastPublishedDate',
                                type: 'datetime' as 'datetime',
                            }, {
                                name: 'Uppdrag',
                                key: 'publishedCount',
                                type: 'number' as 'number',
                            }]}
                            data={watchlists.filter((watchlist) => watchlist.publishedCount > 0)}
                            onRowClick={({ id }: { id: number }) => goToWatchlistMarket({ watchlistId: id })}
                            emptyText="Du har inga bevakningar med aktuella uppdrag."
                        />
                    </Block>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <Block
                        title="Grupper med aktuella uppdrag"
                        ActionComponent={userProfile?.isAdmin ? (
                            <Button
                                variant="outlined"
                                startIcon={<Add />}
                                onClick={() => navigate('/groups/create')}
                            >
                                Ny grupp
                            </Button>
                        ) : undefined}
                    >
                        <Table
                            name="dashboardGroups"
                            columns={[{
                                name: 'Namn',
                                key: 'name'
                            }, {
                                name: 'Senaste uppdrag',
                                key: 'lastPublishedDate',
                                type: 'datetime' as 'datetime',
                            }, {
                                name: 'Uppdrag',
                                key: 'publishedCount',
                                type: 'number' as 'number'
                            }]}
                            data={myGroups.filter((group) => group.publishedCount > 0)}
                            onRowClick={({ id, name }: { id: number, name: string }) => (
                                goToGroupMarket({ groupId: id, groupName: name })
                            )}
                            emptyText="Ni är inte medlem i någon grupp med aktuella uppdrag."
                        />
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
}
