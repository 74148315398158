import React from 'react';
import {
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    OutlinedInput
} from '@mui/material';
import { Send } from '@mui/icons-material';

import toastMessage from '../../utils/toast';
import OfferChatMessage from './OfferChatMessage';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
    fetchOfferMessagesById,
    selectOfferMessagesById
} from '../../redux/actions/offers';
import { createOfferMessage } from '../../api/offers';
import { InfoText } from '../CommonComponents';
import { createNewOfferMessage } from '../../api/jobs';

type PropsType = {
    recipient?: string | null,
    id?: number | null,
    orderId?: number | null,
    canWrite?: boolean,
    maxHeight?: string
}

const FETCH_INTERVAL = 60 * 1000; // 60 seconds

export default function OfferChat({
    recipient = 'Motpart',
    id,
    orderId,
    canWrite,
    maxHeight
}: PropsType) {
    const dispatch = useDispatch();

    const [newOfferId, setNewOfferId] = React.useState<number | null>(null);
    const [isSending, setIsSending] = React.useState(false);

    const newMessageRef = React.useRef<HTMLInputElement>();
    const messageContainerRef = React.useRef<HTMLDivElement>();

    const targetOfferId = id || newOfferId;
    const messages = useSelector(selectOfferMessagesById(targetOfferId)) || [];

    React.useEffect(() => {
        if (targetOfferId) {
            dispatch(fetchOfferMessagesById(targetOfferId));

            const intervalId = window.setInterval(() => {
                dispatch(fetchOfferMessagesById(targetOfferId));
            }, FETCH_INTERVAL);

            return () => window.clearInterval(intervalId); // Runs before every effect-run, and on unmount
        }

        return () => { };
    }, [dispatch, id, targetOfferId]);

    React.useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [messages?.length]);

    const sendMessage = React.useCallback(async () => {
        const message = newMessageRef.current?.value;

        if (!message || (!id && !orderId)) {
            return;
        }
        try {
            setIsSending(true);
            if (id) {
                await createOfferMessage(id, message);
                dispatch(fetchOfferMessagesById(id));
            } else if (orderId) {
                const { offerId: newId } = await createNewOfferMessage({
                    orderId,
                    message
                });
                setNewOfferId((prevId) => prevId || newId);
            }
            if (newMessageRef.current) {
                newMessageRef.current.value = '';
                newMessageRef.current?.focus();
            }
        } catch (e) {
            // Fixme: Must handle errors better here
            toastMessage(e, 'Kunde inte skicka meddelande, försök igen');
        } finally {
            setIsSending(false);
        }
    }, [dispatch, id, orderId]);

    const onKeyDown = React.useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    }, [sendMessage]);

    return (
        <>
            <Box
                ref={messageContainerRef}
                sx={{ maxHeight, overflowY: 'auto', marginBottom: 1 }}
            >
                {canWrite && messages && messages.length === 0 && (
                    <InfoText>
                        Meddelanden du skickar är synliga för samtliga
                        användare i både ditt och motpartens företag.
                    </InfoText>
                )}
                {messages && messages.map((message) => (
                    <OfferChatMessage
                        key={message.id}
                        message={message}
                    />
                ))}
            </Box>

            {canWrite ? (
                <OutlinedInput
                    id="new-message"
                    inputRef={newMessageRef}
                    disabled={isSending}
                    fullWidth
                    multiline
                    color="info"
                    placeholder={`Skriv något till ${recipient}`}
                    endAdornment={(
                        <InputAdornment position="end">
                            {isSending ? <CircularProgress size={30} color="info" /> : (
                                <IconButton
                                    aria-label="Skicka"
                                    color="info"
                                    onClick={sendMessage}
                                    edge="end"
                                >
                                    <Send />
                                </IconButton>
                            )}
                        </InputAdornment>
                    )}
                    onKeyDown={onKeyDown}
                />
            ) : (
                <InfoText showBorder>
                    Uppdraget är inte längre aktivt, så ni kan inte skriva nya meddelanden.
                </InfoText>
            )}
        </>
    );
}
