import React from 'react';
import { Chip } from '@mui/material';
import { Info } from '@mui/icons-material';

import { OfferStatusType } from '../../types';
import { formatDateTime, formatNumber } from '../../utils/parsing';
// import { COLOR_NEW_BLUE, COLOR_TEAL } from '../../theme';

function getOfferStatusColor(status: OfferStatusType) {
    switch (status) {
        case 'ACCEPTED':
            return 'primary';

        case 'REJECTED':
            return 'error';

        case 'EXPIRED':
            return 'error';

        case 'PENDING':
            return 'default';

        default:
            return 'default';
    }
}

function getOfferStatusText({ status, expiresAt, rejectedAt }: {
    status: OfferStatusType;
    expiresAt: string | null;
    rejectedAt: string | null;
}) {
    switch (status) {
        case 'ACCEPTED':
            return 'Accepterat';

        case 'REJECTED':
            return `Avböjt ${formatDateTime(rejectedAt)}`;

        case 'EXPIRED':
            return `Utlöpt ${formatDateTime(expiresAt)}`;

        case 'PENDING':
            return expiresAt
                ? `Besvaras senast ${formatDateTime(expiresAt)}`
                : 'Gäller tillsvidare';

        default:
            return '-';
    }
}

export function OfferStatusChip({
    price,
    status,
    expiresAt,
    rejectedAt
}: {
    price: number | null;
    status: OfferStatusType;
    expiresAt: string | null;
    rejectedAt: string | null;
}) {
    return !price ? null : (
        <Chip
            color={getOfferStatusColor(status)}
            label={getOfferStatusText({ status, expiresAt, rejectedAt })}
        />
    );
}

export function OfferPriceChip({ price, isOwnOffer }: { price: number | null, isOwnOffer: boolean }) {
    if (!price) {
        return (
            <Chip
                label="Inget prisförslag"
                color="default"
                // sx={{ backgroundColor: COLOR_NEW_BLUE, color: '#fff' }}
            />
        );
    }

    return isOwnOffer
        ? (
            <Chip
                label={`Ni erbjuder ${formatNumber(price, 'SEK')}`}
                color="secondary"
                // sx={{ backgroundColor: COLOR_NEW_BLUE, color: '#fff' }}
            />
        ) : (
            <Chip
                label={`Ni erbjuds ${formatNumber(price, 'SEK')}`}
                color="primary"
                // sx={{ backgroundColor: COLOR_TEAL, color: '#fff' }}
            />
        );
}
