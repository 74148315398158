import { subMinutes } from 'date-fns';
import { pickBy } from 'lodash';

import type { RootStateType, ThunkDispatchType } from '../index';
import market, { MarketSettingsType, defaultMarketFilters } from '../slices/market';
import groups from '../slices/groups';
import watchlists from '../slices/watchlists';

import { getJob, getJobs } from '../../api/jobs';
import { getOpenCapacities, getOpenCapacity } from '../../api/capacities';

import { pickLastByKey } from '../../utils';
import toastMessage from '../../utils/toast';
import { filterMarketEntities } from '../../utils/filter-market-entities';

import type { OrderTypeType } from '../../types';

export const {
    resetLastAllOpenJobsFetch,
    resetLastAllOpenCapacitiesFetch
} = market.actions;

export function setSelectedMarketEntity(keyToSet?: string | null) {
    return async (dispatch: ThunkDispatchType, getState: () => RootStateType) => {
        // If keyToSet is undefined we just refetch the currently selected marekt entity
        const key = typeof keyToSet !== 'undefined'
            ? keyToSet
            : getState().market.selectedMarketEntity?.key ?? null;

        let entity = null;

        if (key !== null) {
            const [type, id] = key.split('-');
            if (type === 'CAP') {
                try {
                    entity = await getOpenCapacity(id);
                } catch {
                    entity = null;
                    dispatch(market.actions.removeOpenEntity({ key }));
                    toastMessage('Kapaciteten är inte längre tillgängligt.');
                }
            } else {
                try {
                    entity = await getJob({ type: type as OrderTypeType, id });
                } catch {
                    entity = null;
                    dispatch(market.actions.removeOpenEntity({ key }));
                    toastMessage('Uppdraget är inte längre tillgängligt.');
                }
            }
        }

        dispatch(market.actions.setSelectedMarketEntity({ data: entity }));
    };
}

export function setMarketSettings(settings: MarketSettingsType) {
    return async (dispatch: ThunkDispatchType) => {
        dispatch(market.actions.setSettings({ data: settings }));
    };
}

export function fetchAllOpenJobs(onlyFetchIfNotRecentlyFetched = false) {
    return async (dispatch: ThunkDispatchType, getState: () => RootStateType) => {
        const { lastAllOpenJobsFetch } = getState().market;

        if (onlyFetchIfNotRecentlyFetched
            && lastAllOpenJobsFetch
            && lastAllOpenJobsFetch > subMinutes(new Date(), 2)
        ) {
            return;
        }

        try {
            dispatch(market.actions.fetchAllOpenJobs());
            const data = await getJobs({ status: 'PUBLISHED' });
            dispatch(market.actions.allOpenJobsFetched({ data }));

            // Make sure that the selected job is still published
            const { selectedMarketEntity } = getState().market;
            if (selectedMarketEntity?.key) {
                // This will refetch the entity, if 404 - it will be set to null.
                setSelectedMarketEntity(selectedMarketEntity.key);
            }

            // Update watchlists publishedCount
            const updatedWatchlists = getState().watchlists.all.map((w: any) => {
                const watchlistMarketEntities = filterMarketEntities({
                    entities: [...data, ...getState().market.allOpenCapacities],
                    filters: w
                });
                return ({
                    ...w,
                    publishedCount: watchlistMarketEntities.length,
                    lastPublishedDate: pickLastByKey(watchlistMarketEntities, 'publishedAt')
                });
            });
            dispatch(watchlists.actions.allFetched({ data: updatedWatchlists }));

            // Update myGroups publishedCount
            const updatedGroups = getState().groups.my.map((g: any) => {
                const groupMarketEntities = filterMarketEntities({
                    entities: [...data, ...getState().market.allOpenCapacities],
                    filters: { isJob: true, isCapacity: true, groupId: g.id }
                });
                return ({
                    ...g,
                    publishedCount: groupMarketEntities.length,
                    lastPublishedDate: pickLastByKey(groupMarketEntities, 'publishedAt')
                });
            });
            dispatch(groups.actions.myFetched({ data: updatedGroups }));
        } catch (e) {
            console.log('ERROR fetching open jobs, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function fetchAllOpenCapacities(onlyFetchIfNotRecentlyFetched = false) {
    return async (dispatch: ThunkDispatchType, getState: () => RootStateType) => {
        const { lastAllOpenCapacitiesFetch } = getState().market;

        if (onlyFetchIfNotRecentlyFetched
            && lastAllOpenCapacitiesFetch
            && lastAllOpenCapacitiesFetch > subMinutes(new Date(), 2)
        ) {
            return;
        }

        try {
            dispatch(market.actions.fetchAllOpenCapacities());
            const data = await getOpenCapacities();
            dispatch(market.actions.allOpenCapacitiesFetched({ data }));

            // Make sure that the selected job is still published
            const { selectedMarketEntity } = getState().market;
            if (selectedMarketEntity?.key) {
                // This will refetch the entity, if 404 - it will be set to null.
                setSelectedMarketEntity(selectedMarketEntity.key);
            }

            // Update watchlists publishedCount
            const updatedWatchlists = getState().watchlists.all.map((w: any) => {
                const watchlistMarketEntities = filterMarketEntities({
                    entities: [...getState().market.allOpenJobs, ...data],
                    filters: w
                });
                return ({
                    ...w,
                    publishedCount: watchlistMarketEntities.length,
                    lastPublishedDate: pickLastByKey(watchlistMarketEntities, 'publishedAt')
                });
            });
            dispatch(watchlists.actions.allFetched({ data: updatedWatchlists }));

            // Update myGroups publishedCount
            const updatedGroups = getState().groups.my.map((g: any) => {
                const groupMarketEntities = filterMarketEntities({
                    entities: [...getState().market.allOpenJobs, ...data],
                    filters: { isJob: true, isCapacity: true, groupId: g.id }
                });
                return ({
                    ...g,
                    publishedCount: groupMarketEntities.length,
                    lastPublishedDate: pickLastByKey(groupMarketEntities, 'publishedAt')
                });
            });
            dispatch(groups.actions.myFetched({ data: updatedGroups }));
        } catch (e) {
            console.log('ERROR fetching open capacities, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export const selectAllOpenJobs = () => (state: RootStateType) => state.market.allOpenJobs;
export const selectFetchingAllOpenJobs = () => (state: RootStateType) => state.market.fetchingAllOpenJobs;

export const selectAllOpenCapacities = () => (state: RootStateType) => state.market.allOpenCapacities;
export const selectFetchingAllOpenCapacities = () => (state: RootStateType) => state.market.fetchingAllOpenCapacities;

export const selectFilteredMarketEntities = () => (state: RootStateType) => state.market.filteredMarketEntities;
export const selectFilteredMarketEntitiesCount = () => (state: RootStateType) => state.market.filteredMarketEntitiesCount;

export const selectSelectedMarketEntity = () => (state: RootStateType) => state.market.selectedMarketEntity;

export const selectMarketSettings = () => (state: RootStateType) => state.market.settings;

export const selectMarketFilters = () => (state: RootStateType) => state.market.filters;
export const selectMarketFiltersCount = () => (state: RootStateType) => state.market.filtersCount;

export function setMarketFilters(partialFilters: any) {
    return async (dispatch: ThunkDispatchType) => {
        dispatch(market.actions.setMarketFilters({
            data: {
                watchlistId: null,
                watchlistName: '',
                ...partialFilters
            }
        }));
    };
}
export function resetMarketFilters(partialFilters: any = {}) {
    return async (dispatch: ThunkDispatchType) => {
        dispatch(market.actions.setMarketFilters({ data: { ...defaultMarketFilters, ...partialFilters } }));
    };
}
export function setMarketFiltersFromWatchlist(watchlist: any) {
    const newMarketFilters = watchlist === null || watchlist.id === null ? ({}) : ({
        watchlistId: watchlist.id,
        watchlistName: watchlist.name,
        isJob: watchlist.isJob,
        isCapacity: watchlist.isCapacity,
        pickupLocationQuery: watchlist.pickupLocationQuery,
        pickupLocationName: watchlist.pickupLocationName,
        pickupLocationLat: watchlist.pickupLocationLat,
        pickupLocationLng: watchlist.pickupLocationLng,
        pickupLocationRadius: watchlist.pickupLocationRadius,
        deliveryLocationQuery: watchlist.deliveryLocationQuery,
        deliveryLocationName: watchlist.deliveryLocationName,
        deliveryLocationLat: watchlist.deliveryLocationLat,
        deliveryLocationLng: watchlist.deliveryLocationLng,
        deliveryLocationRadius: watchlist.deliveryLocationRadius,
        minGrossWeight: watchlist.minGrossWeight,
        maxGrossWeight: watchlist.maxGrossWeight,
        minChargeableWeight: watchlist.minChargeableWeight,
        maxChargeableWeight: watchlist.maxChargeableWeight,
        minVolume: watchlist.minVolume,
        maxVolume: watchlist.maxVolume,
        minLoadingMeters: watchlist.minLoadingMeters,
        maxLoadingMeters: watchlist.maxLoadingMeters,
        minPalletPlaces: watchlist.minPalletPlaces,
        maxPalletPlaces: watchlist.maxPalletPlaces,
        minPallets: watchlist.minPallets,
        maxPallets: watchlist.maxPallets,
        // minPieces: watchlist.minPieces,
        // maxPieces: watchlist.maxPieces,
        groupId: watchlist.groupId,
        groupName: watchlist.groupName,
        hasListPrice: watchlist.hasListPrice,
        takesOffer: watchlist.takesOffer
    });

    // Must remove undefined props since its optional to send in props
    const cleanMarketFilters = pickBy(newMarketFilters, (v) => v !== undefined);

    return async (dispatch: ThunkDispatchType) => {
        dispatch(market.actions.setMarketFilters({
            data: {
                ...defaultMarketFilters,
                ...cleanMarketFilters
            }
        }));
    };
}
