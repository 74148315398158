import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    fetchAllOfferedJobs,
    selectFetchingAllOfferedJobs,
    selectAllOfferedJobs
} from '../../../redux/actions/jobs';
import { OfferStatusType, JobForMarketListType } from '../../../types';
import { Table } from '../../CommonComponents';
import { FetchPropsType } from '../../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../../CommonComponents/Fetch/FetchRedux';
import { getOrderDate } from '../../../utils/order';
import { formatNumber } from '../../../utils/parsing';
import { OfferPriceChip, OfferStatusChip } from '../../Offers/OfferChips';

const columns = [{
    name: 'Transportdatum',
    key: 'customOrderDate',
    getValue: getOrderDate
}, {
    name: 'Upphämtningsort',
    key: 'pickupCity'
}, {
    name: 'Leveransort',
    key: 'deliveryCity'
}, {
    name: 'Körsträcka',
    key: 'customDrivingDistance',
    type: 'number' as 'number',
    getValue: (r: { drivingDistance: number }) => formatNumber(Math.round(r.drivingDistance / 1000), 'km')
}, {
    name: 'Totalvikt',
    key: 'grossWeight',
    type: 'kg' as 'kg'
}, {
    name: 'Uppdragsgivare',
    key: 'tenantName'
}, {
    name: 'Pris',
    key: 'customOfferPrice',
    getValue: ({ offer: { price, isOwnOffer } }: {
        offer: {
            price: number | null,
            isOwnOffer: boolean
        }
    }) => (
        <OfferPriceChip
            isOwnOffer={isOwnOffer}
            price={price}
        />
    )
}, {
    name: 'Status',
    key: 'customOfferStatus',
    getValue: ({
        offer: {
            price,
            status,
            expiresAt,
            rejectedAt
        }
    }: {
        offer: {
            price: number | null,
            status: OfferStatusType,
            expiresAt: string | null,
            rejectedAt: string | null
        }
    }) => (
        <OfferStatusChip
            price={price}
            status={status}
            expiresAt={expiresAt}
            rejectedAt={rejectedAt}
        />
    )
}];

function OfferedJobs(props: FetchPropsType<JobForMarketListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();

    const onRowClick = React.useCallback(({ id }: { id: number | string }) => (
        navigate(`/jobs/${id}`)
    ), [navigate]);

    return (
        <Table
            name="offeredJobs"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar intresseanmälda uppdrag...' : 'Ni har inga intresseanmälda uppdrag.'}
            getRowStyle={({ isOpen }: { isOpen: boolean }) => (
                !isOpen
                    ? ({ backgroundColor: '#FCC' })
                    : undefined
            )}
        />
    );
}

export default function OfferedJobsFetched() {
    return (
        <FetchRedux<JobForMarketListType[]>
            fetchEntity={fetchAllOfferedJobs()}
            selectEntity={selectAllOfferedJobs()}
            selectIsFetching={selectFetchingAllOfferedJobs()}
            Component={OfferedJobs}
            name="intresseanmälda uppdragen"
        />
    );
}
