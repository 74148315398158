import React from 'react';
import {
    Box,
    Button,
    Grid,
    Typography
} from '@mui/material';
import {
    Clear as ClearIcon,
    Notifications as NotificationsIcon
} from '@mui/icons-material';

import {
    AutocompleteField,
    BoolField,
    DateField,
    PopupForm,
    RangeField,
    SliderField,
    TextField,
    useChangeable
} from '../CommonComponents/Form';
import { Input } from '../StyledComponents';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
    setMarketFilters,
    resetMarketFilters,
    setMarketFiltersFromWatchlist,
    selectMarketFilters
} from '../../redux/actions/market';
import { marketFilterLimits } from '../../redux/slices/market';
import { LocationPicker, SectionDivider } from '../CommonComponents';
import { LocationType } from '../CommonComponents/GoogleMap/LocationPicker';
import toast from '../../utils/toast';
import { createWatchlist } from '../../api/watchlists';
import { fetchAllWatchlists, selectAllWatchlists } from '../../redux/actions/watchlists';
import { fetchAllGroups, selectMyGroups } from '../../redux/actions/groups';

const defaultWatchlist = {
    name: '',
    instantNotifications: false,
    dailyNotifications: false
};

const MarketFilter: React.FC = () => {
    const dispatch = useDispatch();
    const marketFilters = useSelector(selectMarketFilters());
    const watchlists = useSelector(selectAllWatchlists());
    const groups = useSelector(selectMyGroups());

    const [showWatchlistPopup, setShowWatchlistPopup] = React.useState(false);
    const [newWatchlist, setNewWatchlist] = useChangeable(defaultWatchlist);

    const fetchWatchlists = React.useCallback(() => {
        dispatch(fetchAllWatchlists());
    }, [dispatch]);

    const fetchGroups = React.useCallback(() => {
        dispatch(fetchAllGroups());
    }, [dispatch]);

    const saveWatchlist = React.useCallback(async () => {
        try {
            const { id, name } = await createWatchlist({ ...newWatchlist, ...marketFilters });
            dispatch(setMarketFilters({ watchlistId: id, watchlistName: name }));
            setShowWatchlistPopup(false);
            setNewWatchlist(defaultWatchlist);
            fetchWatchlists();
            toast('Bevakning skapad');
        } catch (e) {
            toast(e, 'Kunde inte skapa bevakning, försök igen.');
        }
    }, [newWatchlist, marketFilters, dispatch, setNewWatchlist, fetchWatchlists]);

    const locationQueryInputRef = React.useRef<HTMLInputElement | null>(null);

    const clearFilters = React.useCallback(() => {
        dispatch(resetMarketFilters());
        if (locationQueryInputRef.current) {
            locationQueryInputRef.current.value = '';
            locationQueryInputRef.current.focus();
        }
    }, [dispatch]);

    return (
        <Box sx={{ p: 2, height: '100%', overflowY: 'auto' }}>
            <PopupForm
                open={showWatchlistPopup}
                title="Skapa bevakning med nuvarande filter"
                okLabel="Skapa"
                cancelLabel="Avbryt"
                handleOk={saveWatchlist}
                handleClose={() => setShowWatchlistPopup(false)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="name"
                            label="Namn"
                            value={newWatchlist.name}
                            onChange={setNewWatchlist}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BoolField
                            name="instantNotifications"
                            label="Direktnotiser"
                            helperText="Få notiser via push, sms eller e-post direkt när ett nytt uppdrag dyker upp i bevakningen."
                            value={newWatchlist.instantNotifications}
                            variant="switch"
                            onChange={setNewWatchlist}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BoolField
                            name="dailyNotifications"
                            label="Dagliga notiser"
                            helperText="Få en daglig summering av nya uppdrag i bevakningen via push, sms eller e-post."
                            variant="switch"
                            value={newWatchlist.dailyNotifications}
                            onChange={setNewWatchlist}
                        />
                    </Grid>
                </Grid>
            </PopupForm>

            <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<ClearIcon />}
                        onClick={clearFilters}
                        fullWidth
                    >
                        Rensa
                    </Button>
                </Grid>
                <Grid item xs>
                    <Button
                        variant="outlined"
                        startIcon={<NotificationsIcon />}
                        onClick={() => setShowWatchlistPopup(true)}
                        fullWidth
                    >
                        Skapa bevakning
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteField<{ id: number | string, name: string | null, publishedCount?: number }>
                        label="Hämta värden från bevakning"
                        optionIdKey="id"
                        displayOption={!marketFilters.watchlistId ? null : {
                            id: marketFilters.watchlistId,
                            name: marketFilters.watchlistName
                        }}
                        fetchOptions={fetchWatchlists}
                        options={watchlists}
                        getOptionName={(o) => o.name || 'Namnlös bevakning'}
                        getOptionDescription={(o) => `${o.publishedCount} träff${o.publishedCount === 1 ? '' : 'ar'}`}
                        onChange={(watchlist) => dispatch(setMarketFiltersFromWatchlist(watchlist))}
                        fetchOnlyOnce
                        variant="outlined"
                        size="small"
                    />
                </Grid>
            </Grid>

            <SectionDivider label="Typ" marginBottom={0} marginTop={2} />
            <Grid container spacing={0} justifyContent="space-between">
                <Grid item>
                    <BoolField
                        name="isJob"
                        label="Uppdrag"
                        value={marketFilters.isJob}
                        onChange={({ isJob }) => dispatch(setMarketFilters({ isJob }))}
                    />
                </Grid>
                <Grid item>
                    <BoolField
                        name="isCapacity"
                        label="Kapacitet"
                        value={marketFilters.isCapacity}
                        onChange={({ isCapacity }) => dispatch(setMarketFilters({ isCapacity }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BoolField
                        name="takesOffer"
                        label="Dölj externa"
                        value={marketFilters.takesOffer}
                        onChange={({ takesOffer }) => dispatch(setMarketFilters({ takesOffer }))}
                    />
                </Grid>
            </Grid>

            <SectionDivider label="Område" marginBottom={2} marginTop={2} />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Input
                        inputRef={locationQueryInputRef}
                        name="locationQuery"
                        label="Snabbsök orter"
                        variant="outlined"
                        size="small"
                        autoFocus
                        defaultValue={marketFilters.locationQuery}
                        onChange={({ target }: { target: { value: string }}) => {
                            dispatch(setMarketFilters({ locationQuery: target.value }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LocationPicker
                        label="Upphämtningsområde"
                        defaultText={marketFilters.pickupLocationName}
                        onChange={(location: LocationType | null) => {
                            dispatch(setMarketFilters({
                                pickupLocationQuery: location ? `${location.city || 'Namnlös'}` : '',
                                pickupLocationName: location ? `${location.city || 'Namnlös'}` : '',
                                pickupLocationLat: location ? location.lat : null,
                                pickupLocationLng: location ? location.lng : null
                            }));
                        }}
                    />
                    {marketFilters.pickupLocationLat && marketFilters.pickupLocationLng && (
                        <Typography variant="caption">
                            Dra kartnålen (U) för att finjustera positionen.
                        </Typography>
                    )}
                </Grid>

                {marketFilters.pickupLocationLat && marketFilters.pickupLocationLng && (
                    <Grid item xs={12}>
                        <SliderField
                            name="pickupLocationRadius"
                            label="Sökradie, upphämtning [km]"
                            value={marketFilters.pickupLocationRadius / 1000}
                            minValue={marketFilterLimits.minLocationRadius}
                            maxValue={marketFilterLimits.maxLocationRadius}
                            onChange={(v) => dispatch(setMarketFilters({ pickupLocationRadius: v * 1000 }))}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <LocationPicker
                        label="Leveranssområde"
                        defaultText={marketFilters.deliveryLocationName}
                        onChange={(location: LocationType | null) => {
                            dispatch(setMarketFilters({
                                deliveryLocationQuery: location ? `${location.city || 'Namnlös'}` : '',
                                deliveryLocationName: location ? `${location.city || 'Namnlös'}` : '',
                                deliveryLocationLat: location ? location.lat : null,
                                deliveryLocationLng: location ? location.lng : null
                            }));
                        }}
                    />
                    {marketFilters.deliveryLocationLat && marketFilters.deliveryLocationLng && (
                        <Typography variant="caption">
                            Dra kartnålen (L) för att finjustera positionen.
                        </Typography>
                    )}
                    {marketFilters.deliveryLocationLat && marketFilters.deliveryLocationLng && (
                        <Grid item xs={12}>
                            <SliderField
                                name="deliveryLocationRadius"
                                label="Sökradie, leverans [km]"
                                value={marketFilters.deliveryLocationRadius / 1000}
                                minValue={marketFilterLimits.minLocationRadius}
                                maxValue={marketFilterLimits.maxLocationRadius}
                                onChange={(v) => dispatch(setMarketFilters({ deliveryLocationRadius: v * 1000 }))}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <SectionDivider label="Datum" marginBottom={1} />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DateField
                        name="firstPickupDate"
                        label="Tidigaste upphämtning"
                        value={marketFilters.firstPickupDate}
                        onChange={({ firstPickupDate }) => dispatch(setMarketFilters({ firstPickupDate }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DateField
                        name="lastDeliveryDate"
                        label="Senaste leverans"
                        value={marketFilters.lastDeliveryDate}
                        onChange={({ lastDeliveryDate }) => dispatch(setMarketFilters({ lastDeliveryDate }))}
                    />
                </Grid>
            </Grid>

            <SectionDivider label="Gods" marginTop={4} marginBottom={1} />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <RangeField
                        name="grossWeight"
                        label="Totalvikt [kg]"
                        minValue={marketFilterLimits.minGrossWeight}
                        maxValue={marketFilterLimits.maxGrossWeight}
                        lowValue={marketFilters.minGrossWeight || marketFilterLimits.minGrossWeight}
                        highValue={marketFilters.maxGrossWeight || marketFilterLimits.maxGrossWeight}
                        onChange={([minGrossWeight, maxGrossWeight]) => {
                            dispatch(setMarketFilters({ minGrossWeight, maxGrossWeight }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RangeField
                        name="chargeableWeight"
                        label="FDR-vikt [kg]"
                        minValue={marketFilterLimits.minChargeableWeight}
                        maxValue={marketFilterLimits.maxChargeableWeight}
                        lowValue={marketFilters.minChargeableWeight || marketFilterLimits.minChargeableWeight}
                        highValue={marketFilters.maxChargeableWeight || marketFilterLimits.maxChargeableWeight}
                        onChange={([minChargeableWeight, maxChargeableWeight]) => {
                            dispatch(setMarketFilters({ minChargeableWeight, maxChargeableWeight }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RangeField
                        name="loadingMeters"
                        label="Flakmeter"
                        minValue={marketFilterLimits.minLoadingMeters}
                        maxValue={marketFilterLimits.maxLoadingMeters}
                        lowValue={marketFilters.minLoadingMeters || marketFilterLimits.minLoadingMeters}
                        highValue={marketFilters.maxLoadingMeters || marketFilterLimits.maxLoadingMeters}
                        onChange={([minLoadingMeters, maxLoadingMeters]) => {
                            dispatch(setMarketFilters({ minLoadingMeters, maxLoadingMeters }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RangeField
                        name="volume"
                        label="Volym [m³]"
                        minValue={marketFilterLimits.minVolume}
                        maxValue={marketFilterLimits.maxVolume}
                        lowValue={marketFilters.minVolume || marketFilterLimits.minVolume}
                        highValue={marketFilters.maxVolume || marketFilterLimits.maxVolume}
                        onChange={([minVolume, maxVolume]) => {
                            dispatch(setMarketFilters({ minVolume, maxVolume }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RangeField
                        name="palletPlaces"
                        label="Pallplatser"
                        minValue={marketFilterLimits.minPalletPlaces}
                        maxValue={marketFilterLimits.maxPalletPlaces}
                        lowValue={marketFilters.minPalletPlaces || marketFilterLimits.minPalletPlaces}
                        highValue={marketFilters.maxPalletPlaces || marketFilterLimits.maxPalletPlaces}
                        onChange={([minPalletPlaces, maxPalletPlaces]) => {
                            dispatch(setMarketFilters({ minPalletPlaces, maxPalletPlaces }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RangeField
                        name="pallets"
                        label="Pallar"
                        minValue={marketFilterLimits.minPallets}
                        maxValue={marketFilterLimits.maxPallets}
                        lowValue={marketFilters.minPallets || marketFilterLimits.minPallets}
                        highValue={marketFilters.maxPallets || marketFilterLimits.maxPallets}
                        onChange={([minPallets, maxPallets]) => {
                            dispatch(setMarketFilters({ minPallets, maxPallets }));
                        }}
                    />
                </Grid>
            </Grid>
            <SectionDivider label="Publicering" marginTop={2} marginBottom={1} />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AutocompleteField<{ id: number, name: string, ownerTenantName?: string }>
                        label="Endast i grupp"
                        optionIdKey="id"
                        displayOption={!marketFilters.groupId ? null : {
                            id: marketFilters.groupId,
                            name: marketFilters.groupName
                        }}
                        fetchOptions={fetchGroups}
                        options={groups.filter(
                            (o: { isOwner: boolean; isAccepted: boolean; }) => (o.isOwner || o.isAccepted)
                        )}
                        getOptionName={(o) => o.name}
                        getOptionDescription={(o) => o.ownerTenantName || ''}
                        onChange={(group) => dispatch(setMarketFilters({
                            groupId: group ? group.id : null,
                            groupName: group ? group.name : null
                        }))}
                        fetchOnlyOnce
                    />
                </Grid>

                <Grid item xs={12}>
                    <BoolField
                        name="hasListPrice"
                        label="Endast uppdrag med riktpris"
                        value={marketFilters.hasListPrice}
                        onChange={({ hasListPrice }) => dispatch(setMarketFilters({ hasListPrice }))}
                    />
                </Grid>

                <Grid item xs={12}>
                    <BoolField
                        name="hasOffer"
                        label="Endast uppdrag där ni anmält intresse"
                        value={marketFilters.hasOffer}
                        onChange={({ hasOffer }) => dispatch(setMarketFilters({ hasOffer }))}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default MarketFilter;
