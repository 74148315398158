import apiRequest from './api-request';

type OfferForCreateType = {
    price: number;
    expiresAt?: Date,
    message?: string
};

const resource = 'offers';

export function getOffersByOrderId(orderId: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `orders/${orderId}/${resource}`
    });
}

export function getActiveOffers() {
    return apiRequest({
        method: 'GET',
        resource: `active-${resource}`
    });
}

export function getOfferMessages(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/${id}/messages`
    });
}

export function createOfferMessage(id: number | string, message: string) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${id}/messages`,
        body: { message }
    });
}

export function createOffer({ orderId, body }: { orderId: number | string, body: OfferForCreateType }) {
    return apiRequest({
        method: 'POST',
        resource: `jobs/${orderId}/offer`,
        body
    });
}

export function updateOffer({ id, body }: { id: number | string, body: OfferForCreateType }) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function withdrawOffer({ id }: { id: number | string }) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${id}/withdraw`
    });
}

export function rejectOffer({ id, price }: { id: number | string, price: number }) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${id}/reject`,
        body: { price }
    });
}

export function acceptOffer({ id, price }: { id: number | string, price: number }) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${id}/accept`,
        body: { price }
    });
}
