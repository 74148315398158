import type { RootStateType, ThunkDispatchType } from '../index';
import jobs from '../slices/jobs';
import { getJobs, getJobStatusCount } from '../../api/jobs';

export function fetchJobStatusCount() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(jobs.actions.fetchStatusCount());
            const data = await getJobStatusCount();
            dispatch(jobs.actions.statusCountFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching jobs, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function fetchAllOfferedJobs() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(jobs.actions.fetchAllOfferedJobs());
            const data = await getJobs({ status: 'OFFERED' });
            dispatch(jobs.actions.allOfferedJobsFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching jobs, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function fetchAllAcceptedJobs() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(jobs.actions.fetchAllAcceptedJobs());
            const data = await getJobs({ status: 'ACCEPTED' });
            dispatch(jobs.actions.allAcceptedJobsFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching jobs, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function fetchAllStartedJobs() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(jobs.actions.fetchAllStartedJobs());
            const data = await getJobs({ status: 'STARTED' });
            dispatch(jobs.actions.allStartedJobsFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching jobs, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function fetchAllCompletedJobs() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(jobs.actions.fetchAllCompletedJobs());
            const data = await getJobs({ status: 'COMPLETED' });
            dispatch(jobs.actions.allCompletedJobsFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching jobs, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export const selectJobStatusCount = () => (state: RootStateType) => state.jobs.statusCount;
export const selectFetchingJobStatusCount = () => (state: RootStateType) => state.jobs.fetchingStatusCount;

export const selectAllOfferedJobs = () => (state: RootStateType) => state.jobs.allOfferedJobs;
export const selectFetchingAllOfferedJobs = () => (state: RootStateType) => state.jobs.fetchingAllOfferedJobs;

export const selectAllAcceptedJobs = () => (state: RootStateType) => state.jobs.allAcceptedJobs;
export const selectFetchingAllAcceptedJobs = () => (state: RootStateType) => state.jobs.fetchingAllAcceptedJobs;

export const selectAllStartedJobs = () => (state: RootStateType) => state.jobs.allStartedJobs;
export const selectFetchingAllStartedJobs = () => (state: RootStateType) => state.jobs.fetchingAllStartedJobs;

export const selectAllCompletedJobs = () => (state: RootStateType) => state.jobs.allCompletedJobs;
export const selectFetchingAllCompletedJobs = () => (state: RootStateType) => state.jobs.fetchingAllCompletedJobs;
