/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

import { getDiffCount, filterKeys } from '../../utils';
import { filterMarketEntities } from '../../utils/filter-market-entities';

import type {
    JobType,
    OpenCapacityType,
    JobForMarketListType,
    OpenCapacityForMarketListType
} from '../../types';

export const defaultMarketFilters = {
    isJob: true,
    isCapacity: true,
    watchlistId: null,
    watchlistName: '',
    pickupLocationQuery: '',
    pickupLocationName: '',
    pickupLocationLat: null,
    pickupLocationLng: null,
    pickupLocationRadius: 100000,
    deliveryLocationQuery: '',
    deliveryLocationName: '',
    deliveryLocationLat: null,
    deliveryLocationLng: null,
    deliveryLocationRadius: 100000,
    firstPickupDate: null,
    lastDeliveryDate: null,
    minGrossWeight: null,
    maxGrossWeight: null,
    minChargeableWeight: null,
    maxChargeableWeight: null,
    minVolume: null,
    maxVolume: null,
    minLoadingMeters: null,
    maxLoadingMeters: null,
    minPalletPlaces: null,
    maxPalletPlaces: null,
    minPallets: null,
    maxPallets: null,
    // minPieces: null,
    // maxPieces: null,
    groupId: null,
    groupName: '',
    hasListPrice: false,
    hasOffer: false,
    takesOffer: false,
    locationQuery: ''
};

export const marketFilterLimits = {
    minLocationRadius: 10,
    maxLocationRadius: 1000,
    stepLocationRadius: 10,
    minGrossWeight: 0,
    maxGrossWeight: 36000,
    stepGrossWeight: 100,
    minChargeableWeight: 0,
    maxChargeableWeight: 36000,
    stepChargeableWeight: 100,
    minVolume: 0,
    maxVolume: 100,
    stepVolume: 1,
    minLoadingMeters: 0,
    maxLoadingMeters: 25,
    stepLoadingMeters: 1,
    minPalletPlaces: 0,
    maxPalletPlaces: 53,
    stepPalletPlaces: 1,
    minPallets: 0,
    maxPallets: 100,
    stepPallets: 1,
    // minPieces: 0,
    // maxPieces: 1000,
    // stepPieces: 1
};

export type MarketSettingsType = {
    settingsOpen?: boolean,
    autoFocus?: boolean,
    showPickupPin?: boolean,
    showDeliveryPin?: boolean,
    showDistanceLine?: boolean
};

const defaultSettings = {
    settingsOpen: false,
    autoFocus: true,
    showPickupPin: true,
    showDeliveryPin: false,
    showDistanceLine: true
};

type MarketStateType = {
    selectedMarketEntity: JobType | OpenCapacityType | null,
    filters: any,
    filtersCount: number,
    settings: MarketSettingsType,

    lastAllOpenJobsFetch: Date | null,
    allOpenJobs: JobForMarketListType[],
    fetchingAllOpenJobs: boolean,

    lastAllOpenCapacitiesFetch: Date | null,
    allOpenCapacities: OpenCapacityForMarketListType[],
    fetchingAllOpenCapacities: boolean,

    filteredMarketEntities: (JobForMarketListType | OpenCapacityForMarketListType)[],
    filteredMarketEntitiesCount: number
};

const initialState: MarketStateType = {
    selectedMarketEntity: null,
    filters: defaultMarketFilters,
    filtersCount: 0,
    settings: defaultSettings,

    lastAllOpenJobsFetch: null,
    allOpenJobs: [],
    fetchingAllOpenJobs: false,

    lastAllOpenCapacitiesFetch: null,
    allOpenCapacities: [],
    fetchingAllOpenCapacities: false,

    filteredMarketEntities: [],
    filteredMarketEntitiesCount: 0
};

const slice = createSlice({
    name: 'market',
    initialState,
    reducers: {
        setSelectedMarketEntity: (state, action) => {
            state.selectedMarketEntity = action.payload.data;
        },
        setMarketFilters: (state, action) => {
            const { data } = action.payload;
            const partialFilters = filterKeys(data, Object.keys(defaultMarketFilters));
            const newFilters = { ...state.filters, ...partialFilters };
            state.filters = newFilters;
            state.filtersCount = getDiffCount(newFilters, defaultMarketFilters, [
                'isJob',
                'isCapacity',
                'watchlistId',
                'watchlistName',
                'pickupLocationQuery',
                'pickupLocationLat',
                'pickupLocationLng',
                'pickupLocationRadius',
                'deliveryLocationQuery',
                'deliveryLocationLat',
                'deliveryLocationLng',
                'deliveryLocationRadius',
                'groupName'
            ]);
            const filteredEntities = filterMarketEntities({
                entities: [...state.allOpenJobs, ...state.allOpenCapacities],
                filters: newFilters
            });
            state.filteredMarketEntities = filteredEntities;
            state.filteredMarketEntitiesCount = filteredEntities.length;
        },
        setSettings: (state, action) => {
            state.settings = {
                ...state.settings,
                ...action.payload.data
            };
        },
        fetchAllOpenJobs: (state) => {
            state.fetchingAllOpenJobs = true;
        },
        allOpenJobsFetched: (state, action) => {
            const { data } = action.payload;
            const filteredEntities = filterMarketEntities({
                entities: [...data, ...state.allOpenCapacities],
                filters: state.filters
            });
            state.allOpenJobs = data;
            state.filteredMarketEntities = filteredEntities;
            state.filteredMarketEntitiesCount = filteredEntities.length;
            state.fetchingAllOpenJobs = false;
            state.lastAllOpenJobsFetch = new Date();
        },
        resetLastAllOpenJobsFetch: (state) => {
            state.lastAllOpenJobsFetch = null;
        },
        fetchAllOpenCapacities: (state) => {
            state.fetchingAllOpenCapacities = true;
        },
        allOpenCapacitiesFetched: (state, action) => {
            const { data } = action.payload;
            const filteredEntities = filterMarketEntities({
                entities: [...state.allOpenJobs, ...data],
                filters: state.filters
            });
            state.allOpenCapacities = data;
            state.filteredMarketEntities = filteredEntities;
            state.filteredMarketEntitiesCount = filteredEntities.length;
            state.fetchingAllOpenCapacities = false;
            state.lastAllOpenCapacitiesFetch = new Date();
        },
        resetLastAllOpenCapacitiesFetch: (state) => {
            state.lastAllOpenCapacitiesFetch = null;
        },
        removeOpenEntity: (state, action) => {
            const newOpenJobs = state.allOpenJobs.filter((j) => j.key !== action.payload.key);
            const newOpenCapacities = state.allOpenCapacities.filter((c) => c.key !== action.payload.key);
            const newFilteredEntities = state.filteredMarketEntities.filter((e) => e.key !== action.payload.key);
            state.allOpenJobs = newOpenJobs;
            state.allOpenCapacities = newOpenCapacities;
            state.filteredMarketEntities = newFilteredEntities;
            state.filteredMarketEntitiesCount = newFilteredEntities.length;
        }
    }
});

export default slice;
